import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./assets/common.css";

const ThemePage = lazy(() => import("./theme"));
const LoginPage = lazy(() => import("./pages/Login"));

function App() {
  return (
    <Router>
      <Suspense>
        <Switch>
          {/* <Route path="/login" component={LoginPage} /> */}
          <Route path="/" component={ThemePage} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
